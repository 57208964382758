
.billprint-container {
    width: auto;
}

.billprint-container {
    background-color: white;
    width: 21cm;
    height: 29cm; 
    padding: .5cm;
}

.billprint-container.landscape {
    background-color: white;
    width: auto;
    height: auto; 
    padding: .5cm;
    /* width: 29cm; */
}

.billprint-container .company-name {
    font-size : 18px;
    font-weight: bold;
}

.billprint-container .company-address, 
.billprint-container .company-city, 
.billprint-container .company-phone{
    font-size: 16px;
} 

.billprint-container .title {
    margin-top: 14px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color : #384392
}

.billprint-container .desc-title {
    text-align: center;
    font-size: 14px;
}

.billprint-container .form-group {
    margin-bottom: 0 !important;
}

.table-kasir > thead > tr > th,
.table-kasir > tbody > tr > td,
.table-kasir > tfoot > tr > td {
    font-size: 14px !important;
    text-align: center !important;
    vertical-align: middle !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

@media print {    
    body {
      margin: 0;
      color: #000;
      background-color: #fff;
      page-break-after:avoid;
    }  
    html, body {
        width: 1024px;
    }
    @page {
        size: a4;
    }
    .billprint-container {
        width: auto;
    }
    body {
        margin: 0 auto;
    }
    nav {
        display: none;
    }

    .company-name {
        font-size : 22pt !important;
        font-weight: bold;
    }

    .company-address, 
    .company-city, 
    .company-phone{
        font-size: 12pt;
    } 

    .billprint-container .table thead tr th,
    .billprint-container .table tbody tr td
    .tb-drr thead tr th,
    .tb-drr tbody tr td{
        font-size: 12pt;
    }

    .transaksi-kas-title {    
        margin : 0 auto;    
        font-size: 14pt;
        font-weight: bold;
    }

    .transaksi-kas-desc {        
        font-size: 12pt;
    }
}