.checkin-meetingroom .garis{
    border-top: 1px solid #cecece;
}
.checkin-meetingroom .box-title{
    padding:10px 0;
}
.checkin-meetingroom .title{
    font-weight: bold;
}

.checkin-meetingroom .box-total .form-group {
    margin-bottom: .5px !important;
}