.tb-drr thead tr th {
    text-align: center;
}

.tb-drr thead tr th,
.tb-drr tbody tr td{
    font-size: 14px;
}

.tb-drr tbody tr td.strong{
    font-weight: bold;
}


