.table-produk tbody tr.active { 
    background-color: #c27d7d;
}

.table > tbody > tr.tr-total > td{
    font-weight: bold !important;
}

.table > tbody > tr.click-td > td {
    cursor: pointer;
}

.table-retur > tbody > tr > td > .form-check-input {
    margin-left: 0 !important;
}