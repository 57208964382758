.strukprint-container {
    background-color: white;
    width: 7.5cm;
    font-size: 12px;
    padding: 10px;
}

.strukprint-container .company-name {
    font-size : 13px;
    font-weight: bold;
}

.strukprint-container .company-address, 
.strukprint-container .company-city, 
.strukprint-container .company-phone{
    font-size: 11px;
} 

.strukprint-container .title {
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;    
}

.strukprint-container .desc {
    padding-bottom: 10px;    
}

.strukprint-container .box-tanggal {
    margin-bottom: 0 !important;
}

.strukprint-container .table tr td {
    padding: 5px 0 5px 0 !important;
    border-top : none;
}

.strukprint-container .table tr.tr-total  td {
    padding: 0 0 4px 0 !important;
    font-weight: normal !important;
}

.strukprint-container .table .tr-border td {
    border-top : 1px solid #c8ced3 !important;
}