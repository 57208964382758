.room-status-container {
    margin-bottom: 10px;
}

.room-status-container.active .room-status-content{
    background-color: #00c0ef !important;
}

.room-status-container.active .room-status-footer{
    background-color: #00a7d1 !important;
}

.room-status-container .room-status-footer {
    display: flex;
    flex-direction: column;
    color : #fff;
    font-size: 11px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.room-status-container.not-active .room-status-content{
    background-color: #dd4b39 !important;
}
.room-status-container.not-active .room-status-footer{
    background-color: #b83f30 !important;
}

.room-status-container.not-active .room-name {
    color : #942e21 !important;
}

.room-status-container.active .room-name {
    color : #057b99 !important;
}



.room-status-content {
    height: 90px;
    display: flex;
    padding-left : 10px;
}

.room-status-content .room-number-container {
    flex : 1;    
}

.room-status-content .room-icon-container {
    display: flex;
    flex-direction: column;
    flex : 1;        
    align-self: center;
    justify-self: center;
    font-weight: bold;
    text-align: center;    
    align-items: center;
    justify-content: center;
}



.room-status-content .room-number-container .room-number-content,
.room-status-content .room-number-container .room-number-state {
    color : #fff;
}

.room-status-content .room-number-container .room-number-state {
    font-size: 12px;
}
.room-status-content .room-number-container .room-number-content {
    font-size : 36px;
    font-weight: bold;
}

.room-status-footer {
    height: 25px;
}

