.btn-edit {
    padding: 0 5px !important;
    font-size: 11px !important;
}

.btn-edit .fa {
    display: unset;
}

.sidebar .nav-dropdown-items .nav-item{
    margin-left: 15px;
}

.alert-absolute{
    position: fixed;
    right: 10px;
    bottom: 50%;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #fee2e1; */
    background-color: rgba(254, 226, 225, 0.75);
    border-color: #fdd6d6;
    border-radius: 0.25rem;
    width: 300px;
}

.alert-absolute:hover{
    background-color: rgb(254, 226, 225);
}

.alert-absolute .alert-text {
    flex: 4;
    color: #813838;        
    padding: 0.75rem 1.25rem;
    /* max-width: 3000px; */
}

.alert-absolute .close-button {
    flex : 1;    
    padding: 0 10px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.box-billing-detail .row.form-group {
    margin-bottom: 1px !important;
}

.box-bayar .row.form-group {
    margin-bottom: 5px !important;
}

.box-bayar .row.form-group.refund-box{
    margin-bottom: 20px !important;
}

.box-bayar .row.form-group.box-lebih{
    margin-bottom: 20px !important;
    padding-bottom: 10px !important;
}

.box-lebih .form-control::placeholder {
    color : #bfc6ce
}

.box-bayar .row.form-group.refund-box label {
    color : #9b0101;
    font-weight: bold;
    padding-bottom: 18px;
}

.box-billing-detail .table {
    margin-top: 10px;
}

.no-bottom .form-group {
    margin-bottom: 0 !important;
}

.box-change-room .form-check-input {
    margin-left: 0 !important;
}

.table-center thead tr th {
    text-align: center;
    vertical-align: middle;
}

.table-small thead tr th,
.table-small tbody tr td{
    font-size: 12px;
}

.table-extra-small thead tr th,
.table-extra-small tbody tr td{
    font-size: 10px;
}


.transaksi-kas-title {    
    margin : 0 auto;    
    font-size: 18px;
    font-weight: bold;
}

.transaksi-kas-desc {
    margin-bottom: 10px;
    font-size: 16px;
}

.company-name {
    font-size : 18px;
    font-weight: bold;
}

.company-address, 
.company-city, 
.company-phone{
    font-size: 12px;
} 

.margin-top-10 {
    margin-top : 30px; 
}

.margin-bottom-10 {
    margin-bottom : 10px; 
}

.margin-bottom-20 {
    margin-bottom : 20px; 
}

.margin-bottom-30 {
    margin-bottom : 30px; 
}

.margin-bottom-5 {
    margin-bottom : 5px; 
}

.custom-container {
    position: relative;    
    background-color: white;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    height: 85vh;
    padding: 20px 30px;    
}

.custom-container .form-group {
    margin-bottom: 1px;
}

.custom-container .form-group select,
.custom-container .form-group label {
    font-size: 12px;
}

.custom-container .box-bayar .form-group {
    margin-bottom: 5px !important;
}

.custom-container .btn-box {
    position: absolute;
    bottom : 0;
    left : 0; 
    margin-right: 0x;   
}

.custom-container .btn-box .col-lg-4 {
    padding-right: 0;
}

.custom-container .btn-box .btn {
    height: 50px;
    border-radius: 0 !important;
    padding: 0 !important;
}

.custom-container .btn-box .btn-save {
    width: 50%;
}

.table-permissions tbody tr.permission-active td {
    background-color: #4578de;
    color : #fff
}