.ul-filter-guest {
    list-style: none !important;
    padding-left: 0 !important;
    margin-top: 10px;
}

.ul-filter-guest li {
    font-size: 13px;
    color : #248cee;
    cursor: pointer;
}

.ul-filter-guest li.active {
    color : #003261;    
}

.guest-table > tbody > tr > td,
.guest-table > thead > tr > th {
    font-size: 12px;
}

.guest-table > thead > tr > th,
.guest-table > thead > tr {
    background-color: white;
}